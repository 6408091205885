import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import clsx from 'clsx';
import moment from 'moment';
import { AMAZON_BASE_URL, MENU_NS } from './Constants';
import Languages from './Languages';
import CartIcon from './CartIcon';

const withMenu = <P extends RouteComponentProps>(Component: React.ComponentType<P>) => {
    const MenuPage: React.FC<RouteComponentProps> = props => {
        const { match } = props;
        const { t } = useTranslation();

        const getClassName = React.useCallback(
            (id: string) =>
                clsx('menu-item', { selected: match.path.startsWith(`/${id}`) }),
            [match.path]
        );

        return (
            <div className='main'>
                <div className='top-strip' />
                <div className='menu'>
                    <div className='logo'>
                        <Link to='/'>
                            <img
                                src={`${AMAZON_BASE_URL}/v2/images/general/logo_transparent.png`}
                            />
                        </Link>
                    </div>
                    <div className='menu-actions'>
                        <div className='right'>
                            <Languages {...props} showSelection redirect={false} />
                            <CartIcon />
                        </div>
                        <div className='links'>
                            <Link to='/about' className={getClassName('about')}>
                                {t(`${MENU_NS}.about`)}
                            </Link>
                            <Link to='/fonts' className={getClassName('fonts')}>
                                {t(`${MENU_NS}.fonts`)}
                            </Link>
                            <Link to='/support' className={getClassName('support')}>
                                {t(`${MENU_NS}.support`)}
                            </Link>
                            <Link
                                to='/useful-info'
                                className={getClassName('useful-info')}>
                                {t(`${MENU_NS}.useful-info`)}
                            </Link>
                            <Link to='/prices' className={getClassName('prices')}>
                                {t(`${MENU_NS}.prices`)}
                            </Link>
                            <Link
                                to='/custom-services'
                                className={getClassName('custom-services')}>
                                {t(`${MENU_NS}.custom-services`)}
                            </Link>
                            <Link
                                to='/legal-notice'
                                className={getClassName('legal-notice')}>
                                {t(`${MENU_NS}.legal-notice`)}
                            </Link>
                            <Link to='/contact' className={getClassName('contact')}>
                                {t(`${MENU_NS}.contact`)}
                            </Link>
                        </div>
                    </div>
                </div>
                <div className='middle-strip' />
                <div className='main-body scrollable'>
                    <Component {...(props as P)} />
                </div>
                <div className='middle-strip' />
                <div className='footer-strip' />
                <div className='footer'>
                    © Anagrafi Fonts {moment().format('YYYY')}. All rights reserved.
                    {t('footer')}:{' '}
                    <a href='https://personalized.at' target='_blank' rel='noreferrer'>
                        Personalized.at
                    </a>
                    . {moment().format('DD.MM.YYYY')}
                </div>
            </div>
        );
    };

    return MenuPage;
};

export default withMenu;
