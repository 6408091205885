import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import GR_TRANSLATION from './translations/gr';
import EN_TRANSLATION from './translations/en';

i18n.use(initReactI18next).init({
    lng: 'el',
    keySeparator: '.',
    interpolation: { escapeValue: false },
    fallbackLng: 'el',
    resources: {
        el: {
            translation: GR_TRANSLATION
        },
        en: {
            translation: EN_TRANSLATION
        }
    }
});

export default i18n;
