import { omit } from 'lodash';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
    withRouter
} from 'react-router-dom';
import { setFilters, setFonts } from './actions/Actions';
import { ABOUT_NS, BASE_URL, CART_NS, FONTS_NS, PRICES_NS } from './Constants';
import Home from './Home';
import Fonts from './Fonts';
import FontDetails from './FontDetails';
import About from './About';
import LegalNotice from './LegalNotice';
import { AppStore, FontTag, FontTagCategory, Store } from './types';
import CustomServices from './CustomServices';
import Contact from './Contact';
import UsefulInfo from './UsefulInfo';
import Support from './Support';
import Prices from './Prices';
import Cart from './Cart';

const App: React.FC<RouteComponentProps> = () => {
    const dispatch = useDispatch();
    const fonts = useSelector(({ app }: Store) => app.fonts);
    const filters = useSelector(({ app }: Store) => app.filters);

    React.useEffect(() => {
        if (!fonts) {
            fetch(`${BASE_URL}/Fonts`)
                .then(res => res.json())
                .then((f: AppStore['fonts']) => {
                    dispatch(setFonts(f));
                });
        }

        if (!filters) {
            Promise.all([
                fetch(`${BASE_URL}/GetFontTagCategories`).then(res => res.json()),
                fetch(`${BASE_URL}/GetFontTags`).then(res => res.json())
            ]).then(([categories, tags]: [FontTagCategory[], FontTag[]]) => {
                dispatch(
                    setFilters(
                        categories.map(c => ({
                            ...omit(c, 'fontTagIDs'),
                            options: c.fontTagIDs.map(id => ({
                                ...tags.find(x => x.id === id),
                                checked: true
                            }))
                        }))
                    )
                );
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dispatch]);

    return (
        <Switch>
            <Route exact path='/' component={Home} />
            <Route exact path={`/${ABOUT_NS}`} component={About} />
            <Route exact path={`/${FONTS_NS}`} component={Fonts} />
            <Route exact path={`/${FONTS_NS}/:font`} component={FontDetails} />
            <Route exact path='/legal-notice' component={LegalNotice} />
            <Route exact path='/custom-services' component={CustomServices} />
            <Route exact path='/contact' component={Contact} />
            <Route exact path='/useful-info' component={UsefulInfo} />
            <Route exact path='/support' component={Support} />
            <Route exact path={`/${PRICES_NS}`} component={Prices} />
            <Route exact path={`/${CART_NS}`} component={Cart} />
            {/* fallback route */}
            <Redirect to='/' />
        </Switch>
    );
};

export default withRouter(App);
