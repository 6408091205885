import { RouterState } from 'connected-react-router';
import { OptionsObject, SnackbarKey, SnackbarMessage } from 'notistack';

export interface Store {
    router?: RouterState;
    app: AppStore;
}

export interface INotification {
    message: SnackbarMessage;
    options: OptionsObject;
    key: SnackbarKey;
}

export interface AppStore {
    notifications: INotification[];
    fonts: Font[];
    filters: (Omit<FontTagCategory, 'fontTagIDs'> & {
        options: (FontTag & { checked: boolean })[];
    })[];
    cart: Font[];
}

export enum Language {
    EL = 'el',
    EN = 'en'
}

export enum CategoryType {
    CATEGORIES = 1,
    LANGUAGES = 2,
    WEIGHTS = 3
}

export type FontTagCategory = {
    id: number;
    nameEn: string;
    nameGr: string;
    type: CategoryType;
    fontTagIDs: number[];
};

export type FontTag = {
    id: number;
    nameEn: string;
    nameGr: string;
    descriptionGr: string;
    descriptionEn: string;
    codeEn: string;
    codeGr: string;
    fontTagCategoryID: number;
};

export interface Font {
    id: number;
    code: string;
    finalPriceAfterAction: number;
    freeFonts: Font[];
    fullPageName: string;
    hasCyrilicSupport: boolean;
    hasDropCapsSupport: boolean;
    hasFullLatinSupport: boolean;
    hasLigaturesSupport: boolean;
    hasPolytonicSupport: boolean;
    hasPromoImgConfigured: boolean;
    hasLogoImgConfigured: boolean;
    hasSubFonts: boolean;
    hasNewVersion: boolean;
    isMainFont: boolean;
    isNewFont: boolean;
    isPackage: boolean;
    isReady: boolean;
    isSpecialFont: boolean;
    mainFontID: number;
    name: string;
    price: number;
    priceActionPercentage: number;
    subFonts: Font[];
    localizations: {
        id: number;
        lang: Language;
        description: string;
        extraDescription: string;
        weightDescription: string;
        mainText: string;
    }[];
    maxLeftColumnCounter: number;
    maxRightColumnCounter: number;
    supportedLanguageIDs: number[];
    weightIDs: number[];
    fontCategoryIDs: number[];
    fontTagIDs: number[];
}
