import { ABOUT_NS, CART_NS, FONTS_NS, MENU_NS, PRICES_NS } from '../Constants';

const EN_TRANSLATION = {
    [`${MENU_NS}.about`]: 'Anagrafi',
    [`${MENU_NS}.fonts`]: 'Fonts',
    [`${MENU_NS}.support`]: 'Support',
    [`${MENU_NS}.prices`]: 'Pricelist',
    [`${MENU_NS}.useful-info`]: 'Useful info',
    [`${MENU_NS}.blog`]: 'Blog',
    [`${MENU_NS}.custom-services`]: 'Custom Services',
    [`${MENU_NS}.contact`]: 'Contact',
    [`${MENU_NS}.legal-notice`]: 'Legal Notice',
    footer: 'Design & Development',
    [`${FONTS_NS}.new_font`]: 'NEW',
    [`${FONTS_NS}.weight_label`]: 'Weights',
    [`${FONTS_NS}.price_label`]: 'Price',
    [`${FONTS_NS}.languages_label`]: 'Languages',
    [`${FONTS_NS}.euro`]: 'Euro',
    [`${FONTS_NS}.add_to_cart`]: 'Add to cart',
    [`${FONTS_NS}.download`]: 'Click',
    [`${FONTS_NS}.here`]: 'here',
    [`${FONTS_NS}.weight`]: 'weights',
    [`${FONTS_NS}.download_text`]:
        "to download a PDF text sample, as well as this typeset's features.",
    [`${FONTS_NS}.download_all_text`]:
        'to download the complete list of Anagrafi fonts (PDF file - 90Mb).',
    [`${ABOUT_NS}.left_1`]: `I started out three decades ago, admiring old manuscripts’ pictures; in books,
        encyclopedias, magazines, anywhere… I collected pieces of paper with graphic
        features, a sui generis collection of human diversity. At that time, computers did
        not exist or were falling short from our knowledge and capacities. Nevertheless,
        in admiring byzantine calligraphy, I found myself tangled in exploring this
        incredibly mysterious world; this aesthetically endless graphic richness, which
        marked the path of enthusiasm and love for the exquisite art of copiers on one
        hand, and the letter form itself on the other. Even though the byzantine typeset
        was almost completely (and yet unjustly) associated with the copying of
        ecclesiastic texts, we must admit the obvious: its evolution into extremely
        highly, almost unthinkable levels of aesthetic integrity, originality and
        functionality. The lack of technical means forced copiers to find solutions, many
        of which are considered aesthetically and technically unsurpassable even to this
        day. The harmonious union of text and initial caps, decorative elements and
        miniature painting was depicted in samples of global radiance, which even
        today, when it is so simple for everyone to typeset a text, cannot be found
        easily! Unfortunately, technological evolution seems to have played a decisive
        role in the simplification of typesetting and printing aesthetics in general.`,
    [`${ABOUT_NS}.left_2`]: `It is true that a virtually erotic relationship has developed between manuscript
        texts and the Greek language, with its rough and smooth breathings, its
        circumflexes and its adscripts, the dative and of course the unforgettable final
        /ν/, all of which have been swept away by the drift of simplification.`,
    [`${ABOUT_NS}.right_1`]: `An established spelling “where every omega, every upsilon, every acute and
        every adscript is but a creek, a down-slope, the vertical side of a rock on the
        curve of a boat’s stern, undulating vineyards, churches’ lintels, bits of white and
        red, here and there, from pigeon-coops and pots of cranesbills”, as Odysseas
        Elytis has wisely pointed out. After all, how could an eminently poetic action,
        such as that of writing and copying, not fit the language that vested some of the
        greatest treasures of world literature? It is true! One could not conceive a
        language and writing so simplified, so pitifully deprived of its cosmopolitan past
        and spirituality. For, in preserving our “precious spelling”, we preserve “the
        profound force of analogies that connects the petty with the grand or the
        crucial with the insignificant, and forms under the shredded surface of the
        phenomena a more solid terrain for my foot – I almost said my soul – to step
        on” (O. Elytis).`,
    [`${ABOUT_NS}.right_2`]: `Nevertheless, I believe that there is still room for a little poetry, a few touches
        of cosmopolitanism, as long as man will need what we call Writing. After all, we
        must not forget that it is technology, which we have burdened with so many
        “sins”, that allows us today the technical possibility of getting a bit closer – as
        much as possible – to the writing style of old copiers and gives us the
        opportunity to view texts from the perspective of a different aesthetic
        orientation. It is the great possibility to have ligatured text - an even minimal
        but essential affinity with the beauty of handwriting -, to use drop caps, giving
        an interesting “aroma” to the text, special technical features such as the
        OpenType features and so many others.`,
    [`${ABOUT_NS}.right_3`]: 'Konstantinos F. Siskakis',
    [`${PRICES_NS}.abbreviations`]: 'ABBREVIATIONS',
    [`${CART_NS}.icon`]: 'your cart',
    [`${CART_NS}.title`]: 'Your cart',
    [`${CART_NS}.added`]: 'The font {{font}} was successfully added to the cart',
    [`${CART_NS}.removed`]: 'The font {{font}} was successfully removed from the cart',
    [`${CART_NS}.table.font`]: 'Font',
    [`${CART_NS}.table.price`]: 'Price',
    [`${CART_NS}.table.empty`]: 'Your cart is empty...',
    [`${CART_NS}.table.remove`]: 'Remove from cart',
    [`${CART_NS}.form.name`]: 'Full name',
    [`${CART_NS}.form.email`]: 'E-mail',
    [`${CART_NS}.form.address`]: 'Address',
    [`${CART_NS}.form.type_private`]: 'Individual [Receipt]',
    [`${CART_NS}.form.type_company`]: 'Company [Invoice]',
    [`${CART_NS}.form.company_info`]: 'Company info',
    [`${CART_NS}.form.comments`]: 'Comments',
    [`${CART_NS}.form.mandatory`]: 'Required fields',
    [`${CART_NS}.form.pay`]: 'Place Order',
    [`${CART_NS}.form.total.private`]: 'Grand Total incl. 20% VAT',
    [`${CART_NS}.form.total.company`]: 'Grand Total excl. VAT',
    [`${CART_NS}.form.success`]: 'Your request was sent successfully'
};

export default EN_TRANSLATION;
