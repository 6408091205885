import * as React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory, History } from 'history';
import { createStore, applyMiddleware, combineReducers } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import {
    ConnectedRouter,
    connectRouter,
    routerMiddleware
} from 'connected-react-router';
import { SnackbarProvider } from 'notistack';
import Notifier from './Notifier';
import STORE from './store';
import Reducers from './reducers/Reducers';
import App from './App';

import './i18n';
import './styles/main.less';

const browserHistory = createBrowserHistory();
const middlewares = [thunk, routerMiddleware(browserHistory)];

if (process.env.NODE_ENV === 'development') {
    const logger = createLogger({
        collapsed: true,
        titleFormatter: action => `${action.type}`
    });
    middlewares.push(logger);
}

const createRootReducer = (history: History) =>
    combineReducers({
        router: connectRouter(history),
        app: Reducers
    });

const configureStore = (initialState = STORE) =>
    createStore(
        createRootReducer(browserHistory),
        initialState,
        applyMiddleware(...middlewares)
    );

ReactDOM.render(
    <Provider store={configureStore()}>
        <ConnectedRouter history={browserHistory}>
            <SnackbarProvider
                maxSnack={4}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                hideIconVariant>
                <Notifier />
                <App />
            </SnackbarProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root')
);
