import { Store, Font } from './types';
import { LOCAL_STORAGE_CART_KEY } from './Constants';

const loadCartFromLocalStorage = (): Font[] => {
    const cartItem = localStorage.getItem(LOCAL_STORAGE_CART_KEY);

    try {
        const cart = JSON.parse(cartItem);
        return cart || [];
    } catch {
        return [];
    }
};

const STORE: Store = {
    app: {
        notifications: [],
        fonts: null,
        filters: null,
        cart: loadCartFromLocalStorage()
    }
};

export default STORE;
