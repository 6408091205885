import React from 'react';
import { useTranslation } from 'react-i18next';
import { AMAZON_BASE_URL, MENU_NS } from './Constants';
import LinkTo from './LinkTo';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';

const Support: React.FC = () => {
    const { t, i18n } = useTranslation();

    const getBody = React.useCallback(() => {
        switch (i18n.language) {
            case 'el':
            case 'en':
            default:
                return (
                    <div>
                        <br />
                        <div className='title'>ΕΓΚΑΤΑΣΤΑΣΗ ΓΡΑΜΜΑΤΟΣΕΙΡΩΝ</div>
                        <div>
                            Oἱ γραμματοσειρὲς «Ἀναγραφὴ» λειτουργοῦν, ἀποκλειστικά, σὲ
                            περιβάλλοντα MacOSX καὶ Windows PC. Παρέχονται σὲ μορφὴ
                            OpenType® ποὺ εἶναι ἀπολύτως συμβατἠ καὶ μὲ τὰ δύο
                            λειτουργικά.
                        </div>
                        <div>
                            Ὅλες οἱ γραμματοσειρὲς οἱ ὁποίες ἀποστέλλονται μέσῳ internet
                            εἶναι σὲ συμπιεσμένη μορφὴ γιὰ τὴν ἀποφυγὴ ἀλλοιώσεων τῶν
                            ἀρχείων. Στὶς περισσότερες περιπτώσεις, μποροῦμε νὰ
                            ἀποσυμπιέσουμε τὸ ἀρχεῖο κάνοντας πάνω του διπλὸ κλίκ. Σὲ
                            περίπτωση ποὺ αὐτὸ δὲν λειτουργήσει, πρέπει νὰ
                            ἐγκαταστήσουμε στὸν ὑπολογιστή μας μιὰ δωρεὰν ἐφαρμογὴ ὅπως
                            τὸ WinZip (<LinkTo href='winzip.com/index.htm' />) ἢ τὸ
                            WinRar (<LinkTo href='win-rar.com' />
                            ).
                        </div>
                        <br />
                        <br />
                        <div className='bold sub-title'>Γραμματοσειρὲς OpenType®</div>
                        <div>
                            Ὁ τύπος γραμματοσειρᾶς OpenType ἀναπτύχθηκε ἀπὸ κοινοῦ ἀπὸ
                            τὶς ἐταιρεῖες Apple καὶ Microsoft καὶ εἶναι ὁ νεώτερος ἀπὸ
                            ὅλους τοὺς τύπους (ἀρχὲς τοῦ 2000). Ἔχει βασιστεῖ στὴ χρήση
                            κωδικοποίησης Unicode καὶ περιέχει ὅπως καὶ ὁ τύπος
                            TrueType, τὰ δεδομένα ἀπεικόνισης ὀθόνης καὶ ἐκτύπωσης σὲ
                            ἕνα καὶ μόνο ἀρχεῖο. Ὅμως, ὁ τύπος OpenType περιλαμβάνει
                            ἐπιπλέον ἕνα πλῆθος δυνατοτήτων, ὅπως ἡ ταυτόχρονη
                            ὑποστήριξη πολλαπλῶν λειτουργικῶν συστημάτων καὶ ἡ
                            ἐκτεταμένη ὑποστήριξη ὁμάδων χαρακτήρων (γλωσσῶν). Ἐπίσης,
                            ἐπιτρέπει τὴν ἀποθήκευση ἔως 65.000 χαρακτήρων. Αὐτό τὸ
                            χαρακτηριστικὸ δίνει στοὺς σχεδιαστὲς γραμματοσειρῶν τὴν
                            ἐλευθερία νὰ συμπεριλάβουν πρόσθετους τύπους χαρακτήρων,
                            ὅπως γιὰ παράδειγμα, μικρὰ κεφαλαία (small caps), χαρακτῆρες
                            παλαιοῦ ὕφους (old style figures), ἐναλλακτικοὺς χαρακτῆρες
                            (alternate characters), συμπλέγματα (ligatures), κλάσματα
                            (fractions), ἀριθμοὺς παλαιοῦ τύπου (old style numerals) καὶ
                            ἄλλα πολλά, τὰ ὁποῖα παλαιότερα, θὰ ἀπαιτοῦσαν τὴ δημιουργία
                            χωριστῶν γραμματοσειρῶν.
                        </div>
                        <div>
                            Σημείωση: Ὅλες οἱ γραμματοσειρὲς OpenType δὲν περιέχουν
                            πάντα ἐπιπλέον χαρακτῆρες ή δυνατότητες (π.χ.
                            αυτοματοποιημένα συμπλέγματα). Μπορεῖ νὰ εἶναι ἁπλὲς
                            μετατροπὲς ἀπὸ τοὺς τύπους PostScript Type 1 ἢ TrueType
                            χωρὶς ἐπιπρόσθετα σύνολα χαρακτήρων. Περισσότερες
                            πληροφορίες γιὰ τὸν τύπο OpenType μπορεῖτε νὰ βρεῖτε στὸ{' '}
                            <LinkTo href='http://www.adobe.com/type/opentype' />
                        </div>
                        <br />
                        <br />
                        <div className='bold sub-title'>
                            Ἐγκατάσταση σὲ λειτουργικὸ Macintosh OSX
                        </div>
                        <div>
                            Γιὰ νὰ ἐνεργοποίησουμε μιὰ γραμματοσειρά, μεταφέρουμε τὰ
                            ἀρχεία της μέσα στὸ φάκελο Fonts, ἀκολουθώντας τὴ διαδρομὴ
                            Macintosh HD {'>'} User {'>'} Library {'>'} Fonts. Μποροῦμε,
                            ἐπίσης, νὰ χρησιμοποιήσουμε ἐφαρμογὲς ἐγκατάστασης καὶ
                            διαχείρισης γραμματοσειρῶν, ὅπως τὸ Font Book (
                            <LinkTo href='http://support.apple.com/kb/HT2509' />) τὸ
                            ὁποῖο βρίσκεται προεγκατεστημένο (δωρεὰν) στὸ λειτουργικὸ
                            MacOSX, ἢ τὸ Linotype FontExplorer X (
                            <LinkTo href='http://www.fontexplorerx.com' />) τὸ ὁποῖο
                            παρέχει ἐπαγγελματικὲς δυνατότητες.
                        </div>
                        <br />
                        <br />
                        <div className='bold sub-title'>
                            Ἐγκατάσταση σὲ λειτουργικὸ Windows PC
                        </div>
                        <div>
                            Γιὰ νὰ ἐγκαταστήσουμε γραμματοσειρὲς OpenType, ἁπλῶς
                            μεταφέρουμε τὰ ἀρχεία στὸν φάκελο «Γραμματοσειρές» (“Fonts”)
                            μέσα στὸν «Πίνακα Ἐλέγχου» (“Control Panel”). Ἐναλλακτικὰ,
                            μὲ διπλό κλὶκ στὸ ἀρχεῖο τῆς γραμματοσειρᾶς, ἐπιλέγουμε
                            «Ἐγκατάσταση» (Install).
                        </div>
                        <br />
                        <br />
                        <div className='title'>ΕΙΣΑΓΩΓΗ ΧΑΡΑΚΤΗΡΩΝ</div>
                        <div className='bold sub-title'>
                            Πληκτρολόγηση εἰδικῶν χαρακτήρων μέσῳ τοῦ δεκαεξαδικοῦ ἢ
                            δεκαδικοῦ κώδικα
                        </div>
                        <br />
                        <div className='bold sub-title'>
                            Λειτουργικὸ Macintosh Mac OSX
                        </div>
                        <div className='bold sub-title'>Δεκαεξαδικὴ εἰσαγωγή</div>
                        <div>
                            Ὁ τρόπος εἰσαγωγῆς Unicode Hex (OS 10.2+) ἐπιτρέπει στὸν
                            χρήστη τὴν εἰσαγωγὴ ὀποιουδήποτε χαρακτήρα, ἐφόσον γνωρίζει
                            τὰ 4 ψηφία ποὺ ἀντιστοιχοῦν (δεύτερη στήλη πινάκων).
                            Μποροῦμε νὰ ἐνεργοποιήσουμε τὸ πληκτρολόγιο Unicode Hex ἀπὸ
                            τὸ System Preferences {'>'} International {'>'} Input Menu.
                        </div>
                        <div>
                            Ἔτσι, κρατώντας πατημένο τὸ πλῆκτρο ALT καὶ πληκτρολογώντας
                            τὴν δεκαεξαδικὴ τιμὴ Unicode -1F0C- ἔχουμε αὐτόματα τὸν
                            χαρακτῆρα - Ἄ- (ἄλφα κεφαλαῖο μὲ ψιλὴ - ὀξεῖα).
                        </div>
                        <br />
                        <br />
                        <div className='bold sub-title'>Λειτουργικὸ Windows PC</div>
                        <div>
                            Στὰ Windows ὑπάρχει, ἐπίσης, διαθέσιμη ἡ δυνατότητα τῆς
                            δεκαδικῆς καὶ δεκαεξαδικῆς εἰσαγωγῆς (Decimal/Hexadecimal
                            Input Method).
                        </div>
                        <br />
                        <br />
                        <div className='bold sub-title'>Δεκαδικὴ εἰσαγωγή</div>
                        <div>
                            Ἡ μέθοδος ALT + δεκαδικὸς ἀπαιτεῖ τὸν κώδικα ἀπὸ τὴν
                            κωδικοποίηση τοῦ χαρακτήρα ποὺ θέλουμε νὰ εἰσάγουμε (τρίτη
                            στήλη πινάκων). Ὁ κωδικὸς αὐτὸς ἀποτελεῖται ἀπὸ ἀριθμητικὰ
                            ψηφία, μόνο.
                        </div>
                        <div>
                            Ἔτσι, κρατώντας πατημένο τὸ πλῆκτρο ALT καὶ πληκτρολογώντας
                            στὸ ἀριθμητικὸ πληκτρολόγιο (δεξιὰ) τὴν δεκαδικὴ τιμὴ
                            Unicode -0163- ἔχουμε αὐτόματα τὸν χαρακτῆρα -£- (pound),
                            ἐνῷ μὲ τὴν τιμὴ -8087- θὰ πάρουμε τὸν χαρακτήρα -ᾗ- (ἦτα
                            πεζὸ μὲ δασεῖα - περισπωμένη καὶ ὑπογεγραμμένη).
                        </div>
                        <br />
                        <br />
                        <div className='bold sub-title'>Δεκαεξαδικὴ εἰσαγωγή</div>
                        <div>
                            Μὲ τὴ μέθοδο αὐτή, πληκτρολογοῦμε τὸν δεκαεξαδικὸ κωδικὸ
                            (δεύτερη στήλη πινάκων), καὶ ἀμέσως μετὰ alt + x. Ὁ κωδικὸς
                            αὐτὸς μπορεῖ νὰ ἀποτελεῖται μόνο ἀπὸ ἀριθμοὺς ἢ ἀπὸ
                            συνδυασμὸ ἀριθμῶν καὶ γραμμάτων.
                        </div>
                        <div>
                            Ἔτσι, πληκτρολογώντας -1F10- καὶ ἀμέσως μετὰ alt + x, θὰ
                            πάρουμε τὸν χαρακτήρα -ἐ- ἔψιλον πεζὸ μὲ ψιλή. Ἄν,
                            ἀντιστρόφως, μετὰ ἀπὸ ἕνα ἤδη ὑπάρχοντα χαρακτήρα
                            πληκτρολογήσουμε alt + x, θὰ ἐμφανιστεῖ στὴ θέση του ὁ
                            δεκαεξαδικὸς κωδικός του.
                        </div>
                        <br />
                        <div>
                            Στὸ ἀρχεῖο “input_charts.zip” (340KB), ποὺ μπορεῖτε νὰ
                            κατεβάσετε{' '}
                            <LinkTo
                                href={`${AMAZON_BASE_URL}/downloads/input_charts.zip`}
                                content='ἐδῶ'
                                className='bold'
                            />{' '}
                            , θὰ βρεῖτε τοὺς πίνακες μὲ τοὺς δεκαδικοὺς καὶ
                            δεκαεξαδικοὺς κωδικοὺς γιὰ ὅλους, σχεδόν, τοὺς χαρακτῆρες
                            ποὺ μπορεῖ νὰ χρειαστεῖ κάποιος (Ἑλληνικά, Ἑλληνικὰ
                            πολυτονικά, Λατινικά, Κυριλλικά, νομισματικά σύμβολα, σημεῖα
                            στίξης κ.λπ.)
                        </div>
                        <br />
                        <br />
                    </div>
                );
        }
    }, [i18n.language]);

    return (
        <ScrollableContainer
            className='support'
            header={t(`${MENU_NS}.support`)}
            hasColumns={false}>
            {getBody()}
        </ScrollableContainer>
    );
};

export default withMenu(Support);
