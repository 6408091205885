import FontAwesome from 'react-fontawesome';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { CART_NS } from './Constants';
import { getCartTotal } from './Helpers';
import { Store } from './types';

const CartIcon: React.FC<{}> = () => {
    const { t } = useTranslation();
    const cart = useSelector(({ app }: Store) => app.cart);

    return (
        <div className='cart'>
            <div className='left'>
                <div>{t(`${CART_NS}.icon`)}</div>
                <div className='total'>€ {getCartTotal(cart)}</div>
            </div>
            <Link to='/cart' className='icon-wrapper'>
                <FontAwesome name='shopping-basket' className='icon' />
            </Link>
        </div>
    );
};

export default CartIcon;
