import { SnackbarKey } from 'notistack';
import * as _ from 'lodash';
import { Action, ActionType, ThunkAction, UpdateFilterOption } from './actionTypes';
import { AppStore, Font, INotification } from '../types';
import i18n from '../i18n';
import { CART_NS } from '../Constants';
import { isFontInCart } from '../Helpers';

// const ERROR_NOTIFICATION: INotification = {
//     message: i18n.t(`${NOTIFICATION_NS}.error`),
//     key: new Date().getTime(),
//     options: {
//         variant: 'error',
//         autoHideDuration: 3000
//     }
// };

export const removeNotification = (key: SnackbarKey): Action => ({
    type: ActionType.REMOVE_NOTIFICATION,
    payload: key
});

export const enqueueNotification = (notification: INotification): Action => ({
    type: ActionType.ENQUEUE_NOTIFICATION,
    payload: notification
});

export const setFonts = (fonts: AppStore['fonts']): Action => ({
    type: ActionType.SET_FONTS,
    payload: fonts
});

export const setFilters = (filters: AppStore['filters']): Action => ({
    type: ActionType.SET_FILTERS,
    payload: filters
});

export const updateFilterOption = (config: UpdateFilterOption['payload']): Action => ({
    type: ActionType.UPDATE_FILTER_OPTION,
    payload: config
});

export const updateCart = (cart: AppStore['cart']): Action => ({
    type: ActionType.UPDATE_CART,
    payload: cart
});

const updateCartAndShowNotification =
    (cart: Font[], action: 'added' | 'removed', fontName: string): ThunkAction =>
    dispatch => {
        dispatch(updateCart(cart));

        dispatch(
            enqueueNotification({
                key: new Date().getTime(),
                message: i18n.t([`${CART_NS}.${action}`], { font: fontName }),
                options: { variant: 'success' }
            })
        );
    };

export const addRemoveToCart =
    (font: Font): ThunkAction =>
    (dispatch, getState) => {
        const { cart, fonts } = getState().app;
        const isInCart = isFontInCart(cart, font.id);
        const parentFont = fonts.find(f => f.subFonts.some(({ id }) => id === font.id));
        let updatedCart: Font[] = [];

        // not in cart
        if (!isInCart) {
            updatedCart = cart.concat(font);
            const cartIds = updatedCart.map(({ id }) => id);

            // parent in cart => remove parent and font and
            // add the rest of the siblings
            if (cartIds.includes(parentFont?.id)) {
                updatedCart = updatedCart
                    .filter(({ id }) => id !== parentFont.id && id !== font.id)
                    .concat(parentFont.subFonts.filter(({ id }) => id !== font.id));

                dispatch(
                    updateCartAndShowNotification(
                        updatedCart,
                        'removed',
                        font.fullPageName
                    )
                );
                return;
            }

            // parent font => remove possible children
            if (!_.isEmpty(font.subFonts)) {
                const childrenIds = font.subFonts.map(({ id }) => id);
                updatedCart = updatedCart.filter(({ id }) => !childrenIds.includes(id));
            } else if (parentFont) {
                // child font => check if all siblings are in the cart
                // and remove them in order to add the parent
                const childrenIds = parentFont.subFonts.map(({ id }) => id);

                if (childrenIds.every(id => cartIds.includes(id))) {
                    updatedCart = updatedCart
                        .concat(parentFont)
                        .filter(({ id }) => !childrenIds.includes(id));
                }
            }

            dispatch(
                updateCartAndShowNotification(updatedCart, 'added', font.fullPageName)
            );
            return;
        }

        // removing
        updatedCart = cart.filter(({ id }) => id !== font.id);
        const cartIds = updatedCart.map(({ id }) => id);

        // child font => check if parent is added =>
        // remove parent and add the rest of the children
        if (_.isEmpty(font.subFonts) && cartIds.includes(parentFont?.id)) {
            updatedCart = updatedCart
                .filter(({ id }) => id !== parentFont.id)
                .concat(parentFont.subFonts.filter(({ id }) => id !== font.id));
        }

        dispatch(
            updateCartAndShowNotification(updatedCart, 'removed', font.fullPageName)
        );
    };
