import React from 'react';
import clsx from 'clsx';
import FontAwesome from 'react-fontawesome';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { AppStore } from './types';
import { updateFilterOption } from './actions/Actions';

type Category = AppStore['filters'][number];

const Filter: React.FC<{ className?: string; width?: number; category: Category }> = ({
    className,
    width = 250,
    category
}) => {
    const [expanded, setExpanded] = React.useState(false);
    const dispatch = useDispatch();
    const { i18n } = useTranslation();

    const toggleExpanded = React.useCallback(() => {
        setExpanded(!expanded);
    }, [expanded]);

    const handleOptionChange = React.useCallback(
        (id: number) => () => {
            dispatch(updateFilterOption({ categoryId: category.id, id }));
        },
        [category.id, dispatch]
    );

    const getName = React.useCallback(
        ({ nameEn, nameGr }: Category['options'][number] | Category) => {
            switch (i18n.language) {
                case 'el':
                    return nameGr;
                case 'en':
                    return nameEn;
                default:
                    return nameGr;
            }
        },
        [i18n.language]
    );

    const renderOptions = React.useCallback(
        () => (
            <div className='options'>
                {category.options.map(option => (
                    <div className='option' key={option.id}>
                        <label className='container'>
                            {getName(option)}
                            <input
                                type='checkbox'
                                checked={option.checked}
                                onChange={handleOptionChange(option.id)}
                            />
                            <span className='checkmark' />
                        </label>
                    </div>
                ))}
            </div>
        ),
        [category.options, getName, handleOptionChange]
    );

    return (
        <div className={clsx('filter-wrapper', className)} style={{ width }}>
            <div className='filter' onClick={toggleExpanded}>
                <div className='label'>{getName(category)}</div>
                <FontAwesome
                    name={expanded ? 'caret-up' : 'caret-down'}
                    className='caret'
                />
            </div>
            {expanded && renderOptions()}
        </div>
    );
};

export default Filter;
