import { ABOUT_NS, CART_NS, FONTS_NS, MENU_NS, PRICES_NS } from '../Constants';

const GR_TRANSLATION = {
    [`${MENU_NS}.about`]: 'Ἀναγραφή',
    [`${MENU_NS}.fonts`]: 'Γραμματοσειρές',
    [`${MENU_NS}.support`]: 'Τεχνικά',
    [`${MENU_NS}.prices`]: 'Τιμές',
    [`${MENU_NS}.useful-info`]: 'Χρήσιμα',
    [`${MENU_NS}.blog`]: 'Blog',
    [`${MENU_NS}.custom-services`]: 'Εἰδικὲς Ἐργασίες',
    [`${MENU_NS}.contact`]: 'Ἐπικοινωνία',
    [`${MENU_NS}.legal-notice`]: 'Νομικά Στοιχεία',
    footer: 'Σχεδίαση & Ανάπτυξη',
    [`${FONTS_NS}.new_font`]: 'ΝΕΑ',
    [`${FONTS_NS}.weight_label`]: 'Βάρη',
    [`${FONTS_NS}.price_label`]: 'Τιμή',
    [`${FONTS_NS}.languages_label`]: 'Γλῶσσες',
    [`${FONTS_NS}.euro`]: 'Εὐρώ',
    [`${FONTS_NS}.add_to_cart`]: 'Ἀποστολὴ στὸ καλάθι',
    [`${FONTS_NS}.download`]: 'Κατεβάστε',
    [`${FONTS_NS}.here`]: 'ἐδῶ',
    [`${FONTS_NS}.weight`]: 'βάρη',
    [`${FONTS_NS}.download_text`]:
        'ἀρχεῖο μὲ δεῖγμα κειμένου, καθὼς καὶ τοὺς χαρακτῆρες τῆς γραμματοσειρᾶς.',
    [`${FONTS_NS}.download_all_text`]:
        'ἀρχεῖο μὲ τὸν συνοπτικὸ κατάλογο ὅλων τῶν γραμματοσειρῶν τῆς Ἀναγραφῆς (ἀρχεῖο PDF - 90Mb).',
    [`${ABOUT_NS}.left_1`]: `Ξεκίνησα πρὶν ἀπὸ τρεῖς δεκαετίες θαυμάζοντας σὲ φωτογραφίες παλιά
        χειρόγραφα. Σὲ βιβλία, ἐγκυκλοπαίδειες, περιοδικά, παντοῦ… Μάζευα
        χαρτάκια μὲ τύπους γραφικῶν χαρακτήρων, μιὰ ἰδιόμορφη συλλογὴ τῆς
        ἀνθρώπινης ἑτερότητας. Ὑπολογιστὲς δὲν ὑπῆρχαν, ἀλλὰ καὶ ἂν ὑπῆρχαν,
        βρίσκονταν πολὺ μακριὰ ἀπὸ τὶς γνώσεις καὶ τὶς δυνατότητές μας. Παρόλα
        αὐτά, θαυμάζοντας τὴ βυζαντινὴ καλλιγραφία, βρέθηκα παγιδευμένος νὰ
        ἐξερευνῶ αὐτὸ τὸν μυστηριώδη κόσμο. Ἕναν ἀπέραντο αἰσθητικὰ πλοῦτο
        γραφῆς ποὺ ἄνοιξε τὸ δρόμο, τὸν ἐνθουσιασμὸ καὶ τὴν ἀγάπη στὴν ἐξαιρετική
        μαστορικὴ τέχνη τῶν ἀντιγραφέων ἀπὸ τὴ μιά, ἀλλὰ καὶ στὴν ἴδια τὴ φόρμα
        τοῦ γράμματος ἀπὸ τὴν ἄλλη. Παρόλο ποὺ ἡ «βυζαντινότροπη» γραφὴ
        ταυτίστηκε σχεδὸν ὁλοκληρωτικὰ (ἀδίκως βέβαια) μὲ τὴν ἀντιγραφὴ
        ἐκκλησιαστικῶν κειμένων, δὲν μποροῦμε παρὰ νὰ παραδεκτοῦμε τὸ
        αὐτονότητο: τὴν ἐξέλιξή της σὲ ἐπίπεδα αἰσθητικῆς ἀρτιότητας, πρωτοτυπίας
        ἀλλὰ καὶ λειτουργικότητας πολῦ ὑψηλά, σχεδόν ἀσύλληπτα. Ἡ ἀνυπαρξία
        τεχνικῶν μέσων ὁδηγοῦσε τοὺς ἀντιγραφεῖς στὴν ἐπινόηση λύσεων, πολλὲς
        ἀπὸ τὶς ὁποῖες θεωροῦνται ἀξεπέραστες αἰσθητικὰ ἀλλὰ καὶ τεχνικά, ἀκόμα καὶ
        σήμερα. Ἡ ἁρμονικὴ σύζευξη κειμένου καὶ ἀρχιγραμμάτων, διακοσμητικῶν
        στοιχείων καὶ μικροζωγραφικῆς, ἔδωσε δείγματα παγκόσμιας ἀκτινοβολίας,
        ποὺ δυστυχῶς ἀκόμα καὶ σήμερα ποὺ εἶναι τόσο εὔκολο γιὰ τὸν καθένα ἀπὸ
        μᾶς νὰ στοιχειοθετήσει ἕνα κείμενο, πολύ δύσκολα συναντῶνται! Δυστυχῶς, ἡ
        τεχνολογικὴ ἐξέλιξη φαίνεται πὼς ἔπαιξε καθοριστικὸ ρόλο στὴν ἁπλοποίηση
        τῆς στοιχειοθεσίας καὶ τῆς τυπογραφικῆς αἰσθητικῆς τοῦ κειμένου γενικότερα.`,
    [`${ABOUT_NS}.left_2`]: `Εἶναι ἀλήθεια, πὼς ἀνάμεσα στὴν ἑλληνικὴ γλῶσσα καὶ τὸ χειρόγραφο κείμενο
        καλλιεργήθηκε μιὰ πραγματικά ἐρωτική σχέση. Μὲ τὶς ψιλές καὶ τὶς δασεῖες,
        τὴν περισπωμένη καὶ τὶς ὑπογεγραμμένες, τὴ δοτικὴ καὶ τὸ ἀξέχαστο τελικὸ
        «νί», ὅλα αὐτά, ἐν τέλει, ποὺ ἐξαφάνισε ἡ λαίλαπα τῆς ἁπλούστευσης.`,
    [`${ABOUT_NS}.right_1`]: `Μὲ καθιερωμένη «μιὰν ὀρθογραφία, ὅπου τὸ κάθε ὠμέγα, τὸ κάθε ὕψιλον, ἡ
        κάθε ὀξεία, ἡ κάθε ὑπογεγραμμένη, δὲν εἶναι παρὰ ἕνας κολπίσκος, μιὰ
        κατωφέρεια, μιὰ κάθετη βράχου πάνω σὲ μιὰ καμπύλη πρύμνας πλεούμενου,
        κυματιστοὶ ἀμπελῶνες, ὑπέρθυρα ἐκκλησιῶν, ἀσπράκια ἢ κοκκινάκια, ἐδῶ ἢ
        ἐκεῖ, ἀπὸ περιστεριῶνες καὶ γλάστρες μὲ γεράνια», ὅπως σοφὰ τονίζει ὁ
        Ὀδυσσέας Ἐλύτης. Πῶς ἀλλοιῶς ἄλλωστε νὰ μὴν ταιριάξει μιὰ πράξη κατεξοχὴν
        ποιητική, ὅπως αὐτὴ τῆς γραφῆς ἢ ἀντιγραφῆς, μὲ τὴ γλώσσα ποὺ ἐπένδυσε
        κάποιους ἀπὸ τοὺς μεγαλύτερους θησαυροὺς τῆς παγκόσμιας γραμματείας;
        Ναί, εἶναι ἀλήθεια! Δὲν θὰ μποροῦσε νὰ διανοηθεῖ κάποιος μιὰ γλώσσα καὶ
        μιὰ γραφὴ τόσο ἁπλοποιημένη, τόσο οἰκτρὰ ἀπογυμνωμένη ἀπὸ τὸ
        κοσμοπολίτικο παρελθὸν καὶ τὴν πνευματικότητά της. Γιατί, διασώζοντας μιὰ
        «πολύτιμη ὀρθογραφία», διασώζουμε «τὴ βαθύτερη ἐκείνη δύναμη τῶν
        ἀναλογιῶν ποὺ συνέχει τὰ παραμικρὰ μὲ τὰ σπουδαῖα ἢ τὰ καίρια μὲ τὰ
        ἀσήμαντα, καὶ διαμορφώνει κάτω ἀπὸ τὴν κατατεμαχισμένη τῶν φαινομένων
        ἐπιφάνεια ἕνα πιὸ στερεὸ ἔδαφος γιὰ νὰ πατήσει τὸ πόδι μου —παραλίγο νὰ
        πῶ ἡ ψυχή μου» (Ὀδ. Ἐλύτης).`,
    [`${ABOUT_NS}.right_2`]: `Ἂς εἶναι, ὅμως. Νομίζω πὼς θὰ ὑπάρχει πάντα χῶρος γιὰ λίγη ποίηση, μερικὲς
        πινελιὲς κοσμοπολιτισμοῦ, ὅσο ὁ ἄνθρωπος θὰ χρειάζεται αὐτὸ ποὺ
        ὀνομάζουμε Γραφή. Κι ὕστερα, ἂς μὴ ξεχνοῦμε πὼς ἡ ἴδια ἡ τεχνολογία στὴν
        ὁποία φορτώνουμε τόσες καὶ τόσες «ἁμαρτίες», εἶναι αὐτὴ ποὺ μᾶς χαρίζει
        σήμερα τὴν τεχνικὴ δυνατότητα νὰ πλησιάσουμε λίγο πιὸ κοντὰ –ὅσο αὐτὸ
        εἶναι δυνατὸν– στὸν τρόπο γραφῆς τῶν παλιῶν μαϊστόρων, τὴν εὐκαιρία νὰ
        δοῦμε κείμενα πρὸς μιὰν ἄλλην κατεύθυνση αἰσθητικῆς. Εἶναι ἡ ἐκείνη
        θαυμαστὴ δυνατοτότητα νὰ ἔχουμε κείμενα μὲ συμπλέξεις –μιὰ ἔστω ἐλάχιστη
        ἀλλὰ οὐσιαστικὴ συγγένεια μὲ τὴν ὀμορφιὰ τῆς ἐπισεσυρμένης γραφῆς–, νὰ
        χρησιμοποιοῦμε ἀρχιγράμματα δίνοντας ἕναν ἄλλο «ἀέρα» στο κείμενο, εἰδικά
        τεχνικὰ χαρακτηριστικὰ ὅπως τὰ OpenType features καὶ τόσα ἄλλα.`,
    [`${ABOUT_NS}.right_3`]: 'Κωνσταντῖνος Φ. Σισκάκης',
    [`${PRICES_NS}.abbreviations`]: 'ΣΥΝΤΟΜΕΥΣΕΙΣ',
    [`${CART_NS}.icon`]: 'τὸ καλάθι σας',
    [`${CART_NS}.title`]: 'Τὸ καλάθι σας',
    [`${CART_NS}.added`]: 'Η γραμματοσειρά {{font}} προστέθηκε επιτυχώς στο καλάθι',
    [`${CART_NS}.removed`]:
        'Η γραμματοσειρά {{font}} αφαιρέθηκε επιτυχώς από το καλάθι',
    [`${CART_NS}.table.font`]: 'Γραμματοσειρά',
    [`${CART_NS}.table.price`]: 'Ποσό',
    [`${CART_NS}.table.empty`]: 'Το καλάθι σας είναι άδειο...',
    [`${CART_NS}.table.remove`]: 'Αφαίρεση απο το Καλάθι',
    [`${CART_NS}.form.name`]: 'Ὀνοματεπώνυμο',
    [`${CART_NS}.form.email`]: 'E-mail',
    [`${CART_NS}.form.address`]: 'Διεύθυνση',
    [`${CART_NS}.form.type_private`]: 'Ἰδιώτης [ἀπόδειξη]',
    [`${CART_NS}.form.type_company`]: 'Ἐταιρεία [τιμολόγιο]',
    [`${CART_NS}.form.company_info`]: 'Στοιχεία Ἐταιρείας/ΑΦΜ',
    [`${CART_NS}.form.comments`]: 'Σχόλια',
    [`${CART_NS}.form.mandatory`]: 'Ὑποχρεωτικὰ πεδία',
    [`${CART_NS}.form.pay`]: 'Αἴτηση Παραγγελίας',
    [`${CART_NS}.form.total.private`]: 'Τελικὸ σύνολο μὲ ΦΠΑ 20%',
    [`${CART_NS}.form.total.company`]: 'Τελικὸ σύνολο χωρὶς ΦΠΑ',
    [`${CART_NS}.form.success`]: 'Τὸ αἴτημά σας στάλθηκε ἐπιτυχῶς'
};

export default GR_TRANSLATION;
