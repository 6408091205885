import React from 'react';
import { useTranslation } from 'react-i18next';
import { MENU_NS } from './Constants';
import LinkTo from './LinkTo';
import withMenu from './MenuHOC';
import ScrollableContainer from './ScrollableContainer';

const LegalNotice: React.FC = () => {
    const { t, i18n } = useTranslation();

    const getBody = React.useCallback(() => {
        switch (i18n.language) {
            case 'en':
                return (
                    <div className='legal-notice'>
                        <br />
                        <div>
                            Anagrafi fonts are sold and distributed exclusively by{' '}
                            <span>Personalized / Fanourios Siskakis</span>.<br />
                            An Invoice or Receipt is issued and charged, as the case may
                            be, with the Austrian VAT (20%).
                        </div>
                        <br />
                        <br />
                        <div className='bold company'>
                            Personalized / Fanourios Siskakis
                            <div>IT Services</div>
                        </div>
                        <br />
                        <div>Address: Gattergasse 2A/1/20, 1110 Vienna, Austria</div>
                        <div>Tel.: +43 680 5063773</div>
                        <div>Bank: Easybank Austria</div>
                        <div>IBAN: AT25 1420 0200 1229 2784</div>
                        <div>BIC: BAWAATWW</div>
                        <div>UID-Nr.: ATU78239158</div>
                        <div>
                            E-mail:{' '}
                            <LinkTo
                                href='mailto:info@personalized.at'
                                content='info@personalized.at'
                            />{' '}
                            ,{' '}
                            <LinkTo
                                href='mailto:invoicing@personalized.at'
                                content='invoicing@personalized.at'
                            />
                        </div>
                        <div>
                            Website:{' '}
                            <LinkTo
                                href='https://personalized.at/'
                                content='personalized.at'
                            />
                        </div>
                    </div>
                );
            case 'el':
            default:
                return (
                    <div className='legal-notice'>
                        <br />
                        <div>
                            Οἱ γραμματοσειρὲς Ἀναγραφὴ πωλοῦνται καὶ διακινοῦνται
                            ἀποκλειστικὰ ἀπὸ τὴν ἐταιρεία{' '}
                            <span>Personalized / Φανούριος Σισκάκης</span>.<br />
                            Ἐκδίδεται Τιμολόγιο ἢ Ἀπόδειξη καὶ ἐπιβαρύνεται, κατὰ
                            περίπτωση, μὲ ΦΠΑ Αὐστρίας (20%).
                        </div>
                        <br />
                        <br />
                        <div className='bold company'>
                            Personalized / Fanourios Siskakis<div>IT Services</div>
                        </div>
                        <br />
                        <div>Διεύθυνση: Gattergasse 2A/1/20, 1110 Vienna, Austria</div>
                        <div>Tηλ.: +43 680 5063773</div>
                        <div>Τράπεζα: Easybank Austria</div>
                        <div>IBAN: AT25 1420 0200 1229 2784</div>
                        <div>BIC: BAWAATWW</div>
                        <div>UID-Nr.: ATU78239158</div>
                        <div>
                            E-mail:{' '}
                            <LinkTo
                                href='mailto:info@personalized.at'
                                content='info@personalized.at'
                            />{' '}
                            ,{' '}
                            <LinkTo
                                href='mailto:invoicing@personalized.at'
                                content='invoicing@personalized.at'
                            />
                        </div>
                        <div>
                            Website:{' '}
                            <LinkTo
                                href='http://www.personalized.at'
                                content='www.personalized.at'
                            />
                        </div>
                    </div>
                );
        }
    }, [i18n.language]);

    return (
        <ScrollableContainer
            className='legal-notice'
            header={t(`${MENU_NS}.legal-notice`)}
            hasColumns={false}>
            {getBody()}
        </ScrollableContainer>
    );
};

export default withMenu(LegalNotice);
